body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/********** GENERAL **********/

body, html {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    min-width: 320px;
    position: relative;
    -webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}


/********** Switch buttons based on live chat status **********/
body.online .enquire {
    display: none;
}

body.offline .livechat {
    display: none;
}


/* ---------------------------------------------- */


/********** Navigation **********/

/* Slight size adjustment */
header {
    font-size: 0.9rem;
}

/* Prevent nav stack */
.navHeader {
    min-width: 320px;
}

/* Nav styling */
.navHeader {
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Logo */
#logoCol {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

#logoRect {
    width: 100%;
    height: auto;
    background: #fff;
    fill: #131313;
    max-width: 180px;
}

#navPhone a, #navCTA a {
    background: #2196F3;
    border-radius: 3px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
    padding: 14px 10px;
    transition: .3s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: cubic-bezier(.25, .1, .2, 1);
}

#navPhone a:before, #navCTA a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #fff 0, #000 100%);
    z-index: 0;
    opacity: .1;
    box-sizing: inherit;
}

#navPhoneMobile {
    padding: 14px;
    display: flex;
    color: #fff;
    align-items: center;
    fill: #fff;
}

#navPhoneMobile svg {
    fill: #fff;
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

#navPhoneDesktop {
    display: inline;
}

#navCTA {
    margin-left: 10px;
}

#navCTA svg {
    fill: #fff;
    width: 16px;
    height: 16px;
    margin-right: 5px;
}


#navCTA a:hover {
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, .15), 0 0 5px 0 rgba(0, 0, 0, .1);
    transform: translateY(-3px);
    color: #fff;
    text-decoration: none;
    transition-timing-function: cubic-bezier(.25, .1, .2, 1);
}


/* Small Devices */
@media (min-width: 576px) {
    #logoSquare {
        max-width: 140px;
    }

    #abtaCol {
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 15px;
    }

    #navAbta {
        width: 100%;
        height: auto;
        max-width: 100px;
    }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
    .navHeader {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #logoRect {
        max-width: 200px;
        margin-right: 30px;
    }

    #navPhone, #navPhone a {
        display: block;
        background: #fff;
        color: #2196F3;
        font-size: 1.2rem;
    }

    #navPhone a:before {
        display: none;
    }

    #navPhone span:first-child {
        display: none;
    }

    #navPhone a {
        box-shadow: none;
    }

    #navPhone a:hover {
        text-decoration: none;
    }

    #navPhoneMobile svg {
        display: none;
    }

    #navCTA {
        font-size: 1.1rem;
    }
}


/* ---------------------------------------------- */


/********** HEADER **********/
.headerBackground {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
    background: #2d343a url(img/bgplaceholder.jpg) center center;
    background-size: cover;
}

#videoBackground {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 5;
}

#videoOverlay {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 10;
    background: rgba(0, 0, 0, .4);
}

#header {
    height: 70vh;
    width: 100%;
    min-width: 100%;
    min-height: 600px;
    max-height: 90vh;
    position: relative;
    overflow: hidden;
    background: #2d343a;
}


@media (min-width: 576px) {
    #header {
        min-height: 600px;
    }
}

@media (min-width: 768px) {
    #header {
        min-height: 600px;
    }
}

@media (min-width: 992px) {
    #header {
        min-height: 800px;
    }
}

.headerContent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 120;
    color: #fff;
}

.headerContent .headerHeader {
    font-size: 1.9rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}


.headerContent .headerHeader > .homeslider {
    display: inline-block;
    vertical-align: middle;
    height: 38px;
    overflow: hidden;
}

.headerContent .headerHeader .headerFirst {
    margin-right: 7px;
    vertical-align: middle;
}


.headerContent p {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 300;
}


.headerCta {
    background: #2196F3;
    font-weight: 500;
    color: #fff;
    padding: 20px 30px 20px 140px;
    display: inline-block;
    border-radius: 4px;
    transition: .3s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: cubic-bezier(.25, .1, .2, 1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
    letter-spacing: normal;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
}

.headerCta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #fff 0, #000 100%);
    z-index: -1;
    opacity: .1;
    box-sizing: inherit;
}

.headerCta strong {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.headerCta:hover {
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, .15), 0 0 5px 0 rgba(0, 0, 0, .1);
    transform: translateY(-3px);
    transition: .3s;
    color: #fff;
    text-decoration: none;
}


.headerCta img {
    fill: #fff;
    height: 100%;
    width: auto;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    opacity: 0.9;
}

/* IOS Fixes */
#videoBackground::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

#videoBackground::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

#videoBackground::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}


/* ---------------------------------------------- */


/********** LISTING SECTION **********/
#main {
    padding: 40px 0;
}


@media (min-width: 768px) {
    #main {
        padding: 60px 0;
    }
}

#villaFilters {
    margin-top: 20px;
}

#villaFilters select {
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    padding: 6px 10px;
    border-radius: 2px;
    margin-top: 5px;
    display: inline-block;
}


@media (min-width: 576px) {
    #filtersWrapper {
        margin-left: -5px;
        margin-right: -5px;
    }

    #villaFilters .villaFilter {
        display: inline-block;
        float: left;
        padding-left: 5px;
        padding-right: 5px;
        width: 50%;
    }

}

@media (min-width: 992px) {
    #filtersWrapper {
        display: flex;
        justify-content: center;
    }

    #villaFilters .villaFilter {
        display: block;
        width: auto;
    }
}


/* ---------------------------------------------- */


/********** VILLA **********/

#villaListings .villaListing {
    font-size: 0.9rem;
    position: relative;
    display: block;
    width: 100%;
    min-height: 400px;
    overflow: hidden;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-top: 30px;
    transition: .3s;
    transform: translate3d(0, 0, 0);
    transition-timing-function: cubic-bezier(.25, .1, .2, 1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
}

#villaListings .villaListing:hover {
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, .15), 0 0 5px 0 rgba(0, 0, 0, .1);
    transform: translateY(-3px) !important;
    color: #fff;
    text-decoration: none;
}

#villaListings .villaMainImg {
    position: absolute;
    left: -10000%;
    right: -10000%;
    top: -10000%;
    bottom: -10000%;
    margin: auto auto;
    min-width: 1000%;
    min-height: 1000%;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    max-width: none;
    height: auto;
    width: auto;
}

#villaListings .villaPhotoCount {
    position: absolute;
    padding: 5px 12px;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
}

#villaListings .villaPhotoCount svg {
    height: 14px;
    width: 14px;
    margin-right: 6px;
    fill: #fff;
}


#villaListings .villaPrice {
    position: absolute;
    padding: 7px 12px;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
}

#villaListings .villaDetails {
    position: absolute;
    padding: 12px 16px;
    background: rgba(0, 0, 0, 0.5);
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
}

#villaListings .villaDetailsLeft {
    flex-grow: 1;
}

#villaListings .villaName {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
}

#villaListings .villaLocale {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}

#villaListings .villaLocale strong {
    font-size: 0.9rem;
    font-weight: 300;
}

#villaListings .villaDestination::after {
    content: '•';
    margin: 0 5px;
}

#villaListings .villaBeds svg {
    display: inline-block;
    height: 22px;
    width: 22px;
    margin-right: 5px;
}

#villaListings .villaBeds span {
    font-size: 1.1rem;
    vertical-align: middle;
}


/* ---------------------------------------------- */


/********** VILLA MODAL **********/


/* ---------------------------------------------- */


/********** Tawk **********/

#tawkchat-container {
    display: none;
}


/* ---------------------------------------------- */


/********** FOOTER **********/

.footerBtn {
    border-radius: 3px 3px 0px 0px;
    margin-left: 15px;
}

.footerBtn:focus, .footerBtn:active {
    outline: 0;
    box-shadow: none;
}

#footerCTA {
    background: #f3f3f3;
    padding-top: 40px;
    padding-bottom: 40px;
}


#footerMeta {
    padding-top: 40px;
    padding-bottom: 40px;
    background: white;
}


