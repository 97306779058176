body.modal-open {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  -webkit-overflow-scrolling: auto;
}

.image-gallery {
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  overflow: hidden;
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;

  .image-gallery-slide {
    .image-gallery-image {
      //img {
      //  width: 700px;
      //  height: auto
      //}
    }
  }

  &.fullscreen {
    .image-gallery-slide {
      img {
        width: 800px;
      }
    }
  }
}

.image-gallery-slide-wrapper {
  button:focus {
    outline: none !important;
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button,
.image-gallery-left-nav,
.image-gallery-right-nav {
  &::before {
    //color: rgba(0, 0, 0, .5);
    color: white;
    text-shadow: none;
  }

  &:hover::before {
    // color: #c8b23b;
    color: white;
    opacity: 0.8;
  }
}

.villa-modal.modal-dialog {
  .modal-header {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    margin-bottom: 0;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;

    button {
      color: white;
      opacity: 1;

      &:hover {
        opacity: 0.8;
        color: white;
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  @media(min-width: 576px) {
    max-width: 800px;
  }
}

.image-gallery-thumbnails {
  padding: 0;

  a.image-gallery-thumbnail {
    padding: 0;
    margin: 0;
    border: 0;
  }
}